<template>
  <section class="space-y-16">
    <div class="bg-[#F7F8FD]">
    <hero-section />
    </div>
    <about-section />
    <services-section />
    <div class="bg-[#F7F8FD]">
    <!-- <team-section /> -->
    </div>
    <Marque />
    <customer-section />
    <project-section/>
    <connect /> 
  </section>
</template>

<script setup lang="ts">
import HeroSection from "@/views/components/HeroSection.vue";
import AboutSection from "@/views/components/AboutSection.vue";
import ServicesSection from "@/views/components/ServicesSection.vue";
// import TeamSection from "@/views/components/TeamSection.vue";
import Marque from "../components/common/Marque.vue";
import CustomerSection from "@/views/components/CustomerSection.vue";
import ProjectSection from "@/views/components/ProjectSection.vue";
import connect from "@/components/common/Connect.vue";
import { useHead } from "@unhead/vue";

useHead({
  title: "Detech Solution | Collaborative partners, not projects.",
  meta: [
    {
      name: "description",
      content:
        "Detech Solution offers innovative tech solutions, focusing on collaboration to create lasting partnerships and deliver exceptional results.",
    },
  ],
  link: [
    {
      rel: "canonical",
      href: "https://detech.com.np/",
    },
  ],
});
</script>
