<template>
  <div class="safe-area space-y-12" id="service">
    <div class="flex flex-wrap justify-between items-center space-y-6">
      <div class="flex flex-col items-start">
        <div class="flex items-center">
          <div class="w-12 h-12 rounded-lg flex items-center">
            <svg
              width="31"
              height="30"
              viewBox="0 0 31 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_394_3034)">
                <g clip-path="url(#clip1_394_3034)">
                  <path
                    d="M26.9984 14.7852H18.2377C16.7257 14.7852 15.5 16.0109 15.5 17.5229V26.2835C15.5 27.7955 16.7257 29.0212 18.2377 29.0212H26.9984C28.5104 29.0212 29.7361 27.7955 29.7361 26.2835V17.5229C29.7361 16.0109 28.5104 14.7852 26.9984 14.7852Z"
                    stroke="#684DF4"
                    stroke-width="1.09508"
                  />
                  <path
                    d="M12.762 14.7852H4.00138C2.48938 14.7852 1.26367 16.0109 1.26367 17.5229V26.2835C1.26367 27.7955 2.48938 29.0212 4.00138 29.0212H12.762C14.274 29.0212 15.4997 27.7955 15.4997 26.2835V17.5229C15.4997 16.0109 14.274 14.7852 12.762 14.7852Z"
                    stroke="#684DF4"
                    stroke-width="1.09508"
                  />
                  <path
                    d="M26.9984 0.546875H18.2377C16.7257 0.546875 15.5 1.77259 15.5 3.28458V12.0452C15.5 13.5572 16.7257 14.7829 18.2377 14.7829H26.9984C28.5104 14.7829 29.7361 13.5572 29.7361 12.0452V3.28458C29.7361 1.77259 28.5104 0.546875 26.9984 0.546875Z"
                    stroke="#684DF4"
                    stroke-width="1.09508"
                  />
                  <path
                    d="M12.762 0.546875H4.00138C2.48938 0.546875 1.26367 1.77259 1.26367 3.28458V12.0452C1.26367 13.5572 2.48938 14.7829 4.00138 14.7829H12.762C14.274 14.7829 15.4997 13.5572 15.4997 12.0452V3.28458C15.4997 1.77259 14.274 0.546875 12.762 0.546875Z"
                    stroke="#684DF4"
                    stroke-width="1.09508"
                  />
                  <path
                    d="M20.4281 6.57031H10.5724C8.75796 6.57031 7.28711 8.04117 7.28711 9.85556V19.7113C7.28711 21.5257 8.75796 22.9965 10.5724 22.9965H20.4281C22.2425 22.9965 23.7133 21.5257 23.7133 19.7113V9.85556C23.7133 8.04117 22.2425 6.57031 20.4281 6.57031Z"
                    fill="#684DF4"
                  />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_394_3034">
                  <rect
                    width="29.5672"
                    height="29.5672"
                    fill="white"
                    transform="translate(0.716797)"
                  />
                </clipPath>
                <clipPath id="clip1_394_3034">
                  <rect
                    width="29.5672"
                    height="29.5672"
                    fill="white"
                    transform="translate(0.716797)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <span class="text-indigo-600 font-semibold uppercase"
            >WHAT WE DO</span
          >
        </div>
        <h2 class="heading-2">
          We Provide Exclusive Services For

          <span class="md:block text-indigo-600">Your Business</span>
        </h2>
      </div>
      <router-link to="/services" class="md:inline-block hidden">
        <button class="primary-btn">View All Services</button>
      </router-link>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
      <ServiceCard
        v-for="service in services"
        :key="service.title"
        :title="service.title"
        :description="service.description"
        :image="service.image"
        :icon="service.icon"
        :link="service.link"
        className="w-[250px] mx-auto"
      />
    </div>
    <router-link to="/services" class="inline-block md:hidden my-4 w-full text-center">
      <button class="primary-btn">View All Services</button>
    </router-link>
  </div>
</template>

<script setup lang="ts">
import ServiceCard from "@/views/services/components/ServiceCard.vue";
import { ref } from "vue";
import { Check } from "lucide-vue-next";
import {
  web_development,
  digital_marketting,
  ui_ux,
  it_consultant,
} from "@/icons/Icons";

const services = ref([
  {
    title: "Web Development",
    description:
      "Custom websites built for performance, scalability, and usability.",
    image: "https://i.postimg.cc/MpDtbCgr/website-development.png",
    icon: web_development,
    link: "/web-development",
  },
  {
    title: "UI/UX Design",
    description:
      "Aesthetic, functional designs delivering impactful user experiences.",
    image: "https://i.postimg.cc/q7D0NZL9/ui-ux.png",
    icon: ui_ux,
    link: "/ui-ux",
  },
  {
    title: "IT Consulting",
    description:
      "Optimize IT, streamline operations, and accelerate transformation. ",
    image: "https://i.postimg.cc/B6pth2ch/it-consulting-service.png",
    icon: it_consultant,
    link: "/it-consulting",
  },
  {
    title: "Digital Marketing",
    description:
      "Comprehensive digital marketing strategies to drive growth and engagement.",
    image: "https://i.postimg.cc/8PTyYdMY/digital-marketting.png",
    icon: digital_marketting,
    link: "/digital-marketing",
  },
]);
</script>
