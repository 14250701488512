<template>
  <section class="safe-area">
    <div class="flex md:flex-row flex-col items-center py-4 justify-between pt-20">
      <div class="space-y-8 lg:flex-[0_0_60%] w-full">
        <div class="">
          <span class="text-primary text-sm font-bold">IT SERVICES</span>
          <h1
            class="text-[2.5rem] md:text-[3.5rem] font-bold text-[#141d38] leading-[1.2]"
          >
            Collaborative
            <br />
            Partners Not Projects
          </h1>
        </div>

        <p class="text-[#737887] text-[15px] md:leading-relaxed text-balance">
          We approach our clients' challenges as if they were our own. While our
          clients focus on driving their business, we take care of the technical
          needs that arise along the way.
        </p>

        <router-link to="/about-us" class="primary-btn inline-block">
          About Us
        </router-link>
      </div>

      <div class="relative hidden lg:flex justify-end  lg:flex-[0_0_40%]">
        <div
          class="bg-[#684DF4] absolute top-12 right-0 h-[300px] w-[300px] blur-[99px] z-[09]"
        ></div>
        <img
          src="https://i.postimg.cc/hvVXXJpf/home-age-banner.webp"
          alt="Professional with laptop"
          class="w-[480px] max-h-[480px] h-full aspect-square relative z-[10]"
        />
      </div>
    </div>
  </section>
</template>
