<template>
  <section class="safe-area">
    <div class="flex md:flex-row flex-col items-start justify-between gap-4">
      <div
        class="aspect-square hidden md:block max-w-[580px] max-h-[470px] xl:flex-[0_0_45%] flex-[0_0_55%]"
      >
        <img
          src="https://i.postimg.cc/gJQw5pBq/our-projects.webp"
          alt="Person working on laptop with coffee"
          class="aspect-square max-w-[450px] h-full w-full max-h-[470px]"
        />
      </div>

      <div class="space-y-6 xl:flex-[0_0_55%] flex-[0_0_45%]">
        <div class="flex flex-col items-start">
          <div class="flex items-center">
            <div class="w-12 h-12 rounded-lg flex items-center">
              <svg
                width="31"
                height="30"
                viewBox="0 0 31 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_394_3034)">
                  <g clip-path="url(#clip1_394_3034)">
                    <path
                      d="M26.9984 14.7852H18.2377C16.7257 14.7852 15.5 16.0109 15.5 17.5229V26.2835C15.5 27.7955 16.7257 29.0212 18.2377 29.0212H26.9984C28.5104 29.0212 29.7361 27.7955 29.7361 26.2835V17.5229C29.7361 16.0109 28.5104 14.7852 26.9984 14.7852Z"
                      stroke="#684DF4"
                      stroke-width="1.09508"
                    />
                    <path
                      d="M12.762 14.7852H4.00138C2.48938 14.7852 1.26367 16.0109 1.26367 17.5229V26.2835C1.26367 27.7955 2.48938 29.0212 4.00138 29.0212H12.762C14.274 29.0212 15.4997 27.7955 15.4997 26.2835V17.5229C15.4997 16.0109 14.274 14.7852 12.762 14.7852Z"
                      stroke="#684DF4"
                      stroke-width="1.09508"
                    />
                    <path
                      d="M26.9984 0.546875H18.2377C16.7257 0.546875 15.5 1.77259 15.5 3.28458V12.0452C15.5 13.5572 16.7257 14.7829 18.2377 14.7829H26.9984C28.5104 14.7829 29.7361 13.5572 29.7361 12.0452V3.28458C29.7361 1.77259 28.5104 0.546875 26.9984 0.546875Z"
                      stroke="#684DF4"
                      stroke-width="1.09508"
                    />
                    <path
                      d="M12.762 0.546875H4.00138C2.48938 0.546875 1.26367 1.77259 1.26367 3.28458V12.0452C1.26367 13.5572 2.48938 14.7829 4.00138 14.7829H12.762C14.274 14.7829 15.4997 13.5572 15.4997 12.0452V3.28458C15.4997 1.77259 14.274 0.546875 12.762 0.546875Z"
                      stroke="#684DF4"
                      stroke-width="1.09508"
                    />
                    <path
                      d="M20.4281 6.57031H10.5724C8.75796 6.57031 7.28711 8.04117 7.28711 9.85556V19.7113C7.28711 21.5257 8.75796 22.9965 10.5724 22.9965H20.4281C22.2425 22.9965 23.7133 21.5257 23.7133 19.7113V9.85556C23.7133 8.04117 22.2425 6.57031 20.4281 6.57031Z"
                      fill="#684DF4"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_394_3034">
                    <rect
                      width="29.5672"
                      height="29.5672"
                      fill="white"
                      transform="translate(0.716797)"
                    />
                  </clipPath>
                  <clipPath id="clip1_394_3034">
                    <rect
                      width="29.5672"
                      height="29.5672"
                      fill="white"
                      transform="translate(0.716797)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <span class="text-indigo-600 font-semibold uppercase">
              our Projects</span
            >
          </div>
          <h2 class="heading-2">
            OUR RECENT
            <span class="text-indigo-600">PROJECTS</span>
          </h2>
          <div
            class="aspect-square block md:hidden max-w-[580px] max-h-[470px] xl:flex-[0_0_45%] flex-[0_0_55%] mt-4"
          >
            <img
              src="https://i.postimg.cc/YSpgNmF6/projects.webp"
              alt="Person working on laptop with coffee"
              class="aspect-square max-w-[450px] h-full w-full max-h-[470px]"
            />
          </div>
        </div>

        <p class="text-[#737887] text-md text-pretty">
          At Detech Solution, we take pride in delivering cutting-edge solutions
          that drive business success. Our expertise spans across various
          industries, helping organizations achieve digital transformation
          through custom software development, enterprise solutions, mobile
          apps, and advanced technologies. Explore our latest projects to see
          how we have helped businesses optimize processes, enhance user
          experiences, and achieve their goals.
        </p>

        <router-link to="/projects" class="block">
          <button class="primary-btn">View Projects</button>
        </router-link>
      </div>
    </div>
  </section>
</template>
